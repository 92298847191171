import React from "react";
import styles from "./AboutMe.module.scss";
import { Link } from "react-router-dom";

const AboutMe = () => {
  return (
    <div className={styles.container}>
      <video playsInline autoPlay muted loop className={styles.AboutVideo}>
        <source
          src="https://firebasestorage.googleapis.com/v0/b/moodpalace-f6317.appspot.com/o/bg-vids%2Faboutsm.mp4?alt=media&token=057bf6d4-7240-4d83-bbc4-7f5339afb12d"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <span className={styles.copy}>
        <Link to="http://dorissaturday.bandcamp.com" target="_blank">
          Doris Saturday
        </Link>{" "}
        is the music practice of{" "}
        <Link to="https://robysaavedra.com" target="_blank">
          Roby Saavedra
        </Link>
        . 
        Roby lives and works in Philadelphia, USA. 
        <p>
        This collection is
        comprised of previously unreleased minimal ambient compositions and
        improvisational sketches recorded in the artist's modest home studio
        over the last 5 or so years.

        </p>
        <p>

        Please enjoy x
        </p>
      </span>
    </div>
  );
};

export default AboutMe;
