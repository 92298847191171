import React, { useRef } from 'react';
import styles from './MoodWindow.module.scss';

const MoodWindow = () => {
  const wrapperRef = useRef(null);

  return (
    <section>
      <div className={styles.MoodWrapper} ref={wrapperRef}>
        <video playsInline autoPlay muted loop>
          <source
            src='https://firebasestorage.googleapis.com/v0/b/moodpalace-f6317.appspot.com/o/bg-vids%2Fmoodpalace_01sm.mp4?alt=media&token=4cb89055-8496-4589-bd95-1682199055e1'
            type='video/mp4'
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};

export default MoodWindow;
