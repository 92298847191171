import React from 'react';
import styles from './Header.module.scss'; // Import your CSS module (if using CSS modules)
import { Link } from 'react-router-dom';
import Modal from './Modal';
import AboutMe from './AboutMe';
import { useState } from 'react';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const tooltipRef = React.useRef(null);

  const handleMouseMove = (e) => {
    const tooltip = tooltipRef.current;
    if (tooltip) {
      tooltip.style.left = `${e.clientX + 10}px`;
      tooltip.style.top = `${e.clientY + 10}px`;
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.tooltipContainer}>
        <button onMouseMove={handleMouseMove} onClick={handleOpenModal}>
          <h1 className={styles.title}>Mood Palace</h1>
        </button>
        <div ref={tooltipRef} className={styles.tooltip}>
          ☮︎ Click for info ☯︎
        </div>
      </div>
      <p className={styles.blurb}>
        A collection of ambient compositions by{' '}
        <Link to='https://robysaavedra.com/' target='_blank'>
          Roby Saavedra
        </Link>
      </p>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={'Mood Palace'}
      >
        <AboutMe />
      </Modal>
    </section>
  );
};

export default Header;
