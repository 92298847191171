import React, { useState } from 'react';
import AudioUploadForm from './components/AudioUploadForm';
import { Link } from 'react-router-dom';
import LoginForm from './components/LoginForm';

const UploadPage = () => {
  const [passwordCorrect, setPasswordCorrect] = useState(false);

  return (
    <div>
      {!passwordCorrect ? (
        <LoginForm setPasswordCorrect={setPasswordCorrect} />
      ) : (
        <>
          <Link to="/">⬅ Home</Link>
          <h2>Audio Upload Page</h2>
          <AudioUploadForm />
        </>
      )}
    </div>
  );
};

export default UploadPage;
