import React, { useState } from 'react';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  updateMetadata,
} from 'firebase/storage';

const AudioUploadForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('idle'); // Possible values: 'idle', 'uploading', 'success', 'error'

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const handleFileUpload = () => {
    if (selectedFile && fileName) {
      const storage = getStorage();
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      setUploadStatus('uploading');

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          setUploadStatus('error');
          console.error('Error uploading file:', error);
        },
        async () => {
          try {
            // Get the upload date and time
            const now = new Date();
            const uploadDate = now.toISOString();

            // Set the custom metadata with the upload date
            await updateMetadata(storageRef, {
              customMetadata: { uploadDate },
            });

            // Fetch the download URL
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log('File uploaded successfully! URL:', downloadURL);

            setSelectedFile(null); // Clear the selected file after successful upload
            setFileName(''); // Clear the file name field after successful upload
            setUploadStatus('success'); // Set uploadStatus to 'success' after successful upload
            setUploadProgress(0); // Reset upload progress
          } catch (error) {
            console.error(
              'Error updating metadata or getting download URL:',
              error
            );
            setUploadStatus('error');
          }
        }
      );
    }
  };

  const handleDismissMessage = () => {
    setUploadStatus('idle');
    setUploadProgress(0);
  };

  return (
    <div>
      <div>
        <label htmlFor='fileInput'>Select Audio File:</label>
        <input id='fileInput' type='file' onChange={handleFileChange} />
      </div>
      <div>
        <label htmlFor='fileNameInput'>File Name:</label>
        <input
          id='fileNameInput'
          type='text'
          value={fileName}
          onChange={handleFileNameChange}
          placeholder='Enter a file name'
        />
      </div>
      {selectedFile && (
        <div>
          <p>Selected file: {selectedFile.name}</p>
          <button onClick={() => setSelectedFile(null)}>Clear File</button>
        </div>
      )}
      <button
        onClick={handleFileUpload}
        disabled={uploadStatus === 'uploading'}
      >
        {uploadStatus === 'uploading' ? 'Uploading...' : 'Upload Audio'}
      </button>
      {uploadStatus === 'uploading' && (
        <p>Uploading... {Math.round(uploadProgress)}%</p>
      )}
      {uploadStatus === 'success' && (
        <div>
          <p>Upload successful!</p>
          <button onClick={handleDismissMessage}>OK</button>
        </div>
      )}
      {uploadStatus === 'error' && (
        <div>
          <p>Error uploading file. Please try again.</p>
          <button onClick={handleDismissMessage}>OK</button>
        </div>
      )}
    </div>
  );
};

export default AudioUploadForm;
