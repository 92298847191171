import React, { useEffect, useRef, useState } from 'react';
import Plyr from 'plyr';
import IconNext from './icons/IconNext';
import IconClose from './icons/IconClose';
import 'plyr/dist/plyr.css';
import styles from './AudioPlayer.module.scss';

const AudioPlayer = ({
  selectedAudio,
  handleCloseAudio,
  handleNextAudio,
  songName,
}) => {
  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  // Watch for changes in the songName prop
  useEffect(() => {
    if (playerRef.current) {
      // Update the player's title when songName changes
      playerRef.current.title = songName;
    }
  }, [songName]);

  useEffect(() => {
    if (selectedAudio) {
      if (playerRef.current) {
        playerRef.current.destroy();
      }

      playerRef.current = new Plyr('#player', {
        controls: ['play-large', 'play'],
        autoplay: true,
        volume: 1,
      });

      playerRef.current.on('timeupdate', (event) => {
        setCurrentTime(playerRef.current.currentTime);
      });

      playerRef.current.on('loadedmetadata', (event) => {
        setDuration(playerRef.current.duration);
      });

      playerRef.current.on('ended', handleNextAudio);

      // Load the new audio source
      playerRef.current.source = {
        type: 'audio',
        sources: [
          {
            src: selectedAudio,
            type: 'audio/mp3',
          },
        ],
      };
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.off('ended', handleNextAudio);
        playerRef.current.destroy();
      }
    };
  }, [selectedAudio, handleCloseAudio, handleNextAudio]);

  return (
    <div className={styles.Player}>
      {selectedAudio && (
        <div className={styles.playercontainer}>
          <div className={styles.playerHeader}>
            <div className={styles.left}>
              <p>now playing:</p>
              <h3>{songName}</h3>
            </div>
            <div className={styles.right}>
              <button onClick={() => handleCloseAudio(false)}>
                <IconClose color='#FDE68A' />
              </button>
            </div>
          </div>
          <div className={styles.playerInner}>
            <div className={styles.left}>
              <audio id='player' controls>
                <source src={selectedAudio} type='audio/mp3' />
                Your browser does not support the audio element.
              </audio>
              <span className={styles.time}>
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
            </div>
            <div className={styles.right}>
              <button onClick={handleNextAudio}>
                <IconNext color='#FDE68A' />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(AudioPlayer);
