import React from 'react';
import { createContext, useContext, useState } from 'react';

const AudioContext = createContext();

export function useAudioContext() {
  return useContext(AudioContext);
}

export function AudioProvider({ children }) {
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  return (
    <AudioContext.Provider
      value={{ selectedAudio, setSelectedAudio, isPlayerOpen, setIsPlayerOpen }}
    >
      {children}
    </AudioContext.Provider>
  );
}
