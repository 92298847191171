import React from 'react';

const IconNext = ({ color = "#FDE68A" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_541_95)">
      <path d="M6 18L14.5 12L6 6V18ZM16 6V18H18V6H16Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_541_95">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconNext;