import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LoginForm.module.scss';

const LoginForm = ({ setPasswordCorrect }) => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  console.log('Password from .env:', process.env.REACT_APP_PASSWORD);

  const handleLogin = (event) => {
    event.preventDefault();
    if (password === process.env.REACT_APP_PASSWORD) {
      setPasswordCorrect(true);
      navigate('/upload');
    } else {
      navigate('/login-error');
    }
  };

  return (
    <form onSubmit={handleLogin} className={styles.loginForm}>
      <input
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder='Enter password'
        required
      />
      {/* Button removed */}
    </form>
  );
};

export default LoginForm;
