import React from "react";
import AudioFileList from "./components/AudioFileList";
import MoodWindow from "./components/MoodWindow";
import styles from "./Home.module.scss";
import Header from "./components/Header";

const Home = () => {
  const isMobileView = window.innerWidth <= 768;

  return (
    <>
      {isMobileView ? (
        <div className={styles.container}>
          <Header />
          <MoodWindow />
          <AudioFileList />
        </div>
      ) : (
        <div className={styles.container}>
          <Header />

          <AudioFileList />
          <MoodWindow />
        </div>
      )}
    </>
  );
};

export default Home;
