// useAudioFiles.js
import { useEffect, useState } from "react";
import { getStorage, ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";

const useAudioFiles = () => {
  const [audioFiles, setAudioFiles] = useState([]);

  useEffect(() => {
    const fetchAudioFiles = async () => {
      const storage = getStorage();
      const storageRef = ref(storage);

      try {
        const listResult = await listAll(storageRef);
        const filePromises = listResult.items.map(async (item) => {
          const downloadURL = await getDownloadURL(item);
          const metadata = await getMetadata(item);
          const timeCreated = new Date(metadata.timeCreated); // Parse timeCreated as a Date object

          // Calculate the difference between the current date and timeCreated in milliseconds
          const timeDifference = new Date() - timeCreated;
          const oneWeekInMilliseconds = 3 * 24 * 60 * 60 * 1000; // One week in milliseconds

          return {
            name: item.name,
            url: downloadURL,
            timeCreated: timeCreated,
            isNew: timeDifference < oneWeekInMilliseconds,
          };
        });

        // Wait for all filePromises to resolve and then sort the files chronologically
        const files = await Promise.all(filePromises);
        files.sort((a, b) => b.timeCreated - a.timeCreated); // Sort files in descending order of timeCreated (most recent first)

        setAudioFiles(files);
      } catch (error) {
        console.error("Error fetching audio files:", error);
      }
    };

    fetchAudioFiles();
  }, []);

  return audioFiles;
};

export default useAudioFiles;
