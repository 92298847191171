import React, { useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AudioProvider } from './components/AudioContext';
import Home from './Home';
import UploadPage from './UploadPage';
import styles from './App.module.scss';

const App = () => {
  const [selectedAudio, setSelectedAudio] = useState(null);

  return (
    <Router>
      <div className={styles.wrapper}>
        <AudioProvider value={{ selectedAudio, setSelectedAudio }}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/upload' element={<UploadPage />} />
          </Routes>
        </AudioProvider>
      </div>
    </Router>
  );
};

export default React.memo(App);
