import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useAudioContext } from './AudioContext';
import styles from './AudioFileList.module.scss';
import IconPlay from '../components/icons/IconPlay';
import IconClose from './icons/IconClose';
import useAudioFiles from '../hooks/useAudioFiles';
import AudioPlayer from './AudioPlayer';

const AudioFileList = () => {
  const audioContext = useAudioContext();
  const audioFiles = useAudioFiles();
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  useEffect(() => {
    const handlePlayAudio = async (fileName) => {
      const storage = getStorage();
      const audioRef = ref(storage, fileName);

      try {
        const downloadURL = await getDownloadURL(audioRef);
        audioContext.setSelectedAudio(downloadURL);
      } catch (error) {
        console.error('Error getting audio URL:', error);
      }
    };

    if (currentFileIndex !== null) {
      handlePlayAudio(audioFiles[currentFileIndex].name);
    }
  }, [currentFileIndex, audioFiles, audioContext]);

  const handleCloseAudio = (autoPlayNext = false) => {
    if (autoPlayNext) {
      handleNextAudio();
    } else {
      audioContext.setSelectedAudio(null);
      setCurrentFileIndex(null);
    }
  };

  const handleNextAudio = () => {
    if (audioFiles.length === 0) {
      return;
    }

    const nextIndex = (currentFileIndex + 1) % audioFiles.length;
    setCurrentFileIndex(nextIndex);
  };

  const handleFileButtonClick = (index) => {
    setCurrentFileIndex(index);
  };

  return (
    <section className={styles.container}>
      {audioContext.selectedAudio && (
        <div className={styles.Player}>
          <AudioPlayer
            selectedAudio={audioContext.selectedAudio}
            handleCloseAudio={handleCloseAudio}
            handleNextAudio={handleNextAudio}
            songName={audioFiles[currentFileIndex]?.name}
          />
        </div>
      )}

      <ul className={styles.AudioFileList}>
        {audioFiles.map((file, index) =>
          currentFileIndex === index ? (
            <button onClick={() => handleCloseAudio(false)} key={index}>
              <li className={styles.ListItem_IsPlaying}>
                <span className={styles.SongName}>
                  <p>
                    {' '}
                    {file.timeCreated.toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </p>
                  {file.isNew && <span className={styles.NewLabel}>New</span>}
                  {`${(audioFiles.length - index)
                    .toString()
                    .padStart(3, '0')} • ${file.name}`}
                </span>

                <IconClose color='#B45309' />
              </li>
            </button>
          ) : (
            <button onClick={() => handleFileButtonClick(index)} key={index}>
              <li className={styles.ListItem}>
                <span className={styles.SongName}>
                  <p>
                    {file.timeCreated.toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </p>
                  {file.isNew && <span className={styles.NewLabel}>New</span>}
                  {`${(audioFiles.length - index)
                    .toString()
                    .padStart(3, '0')} • ${file.name}`}
                </span>

                <IconPlay color='#B45309' />
              </li>
            </button>
          )
        )}
      </ul>
    </section>
  );
};

export default React.memo(AudioFileList);
