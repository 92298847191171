import React from "react";

const CustomSVG = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_537_86)">
        <path d="M8 5V19L19 12L8 5Z" fill={color} /> {/* Use the color prop as fill */}
      </g>
      <defs>
        <clipPath id="clip0_537_86">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomSVG;
